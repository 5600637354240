export const SET_PAGE = 'SET_PAGE'
export const SET_PAGE_DATA = 'SET_PAGE_DATA'

export const SET_NEWS = 'SET_NEWS'
export const SET_NEWS_DATA = 'SET_NEWS_DATA'

export const SET_PLAN = 'SET_PLAN'
export const SET_PLAN_DATA = 'SET_PLAN_DATA'

export const SET_ACCION = 'SET_ACCION'
export const SET_ACCION_DATA = 'SET_ACCION_DATA'

export const SET_INCIDENCIA = 'SET_INCIDENCIA'
export const SET_INCIDENCIA_DATA = 'SET_INCIDENCIA_DATA'

export const SET_SORT = 'SET_SORT'
export const SET_SORT_DATA = 'SET_SORT_DATA'

export const SET_CATEGORY = 'SET_CATEGORY'
export const SET_CATEGORY_DATA = 'SET_CATEGORY_DATA'

export const SET_INDICATOR = 'SET_INDICATOR'
export const SET_INDICATOR_DATA = 'SET_INDICATOR_DATA'

export const SET_CATEGORIES = 'SET_CATEGORIES'
export const SET_CATEGORIES_DATA = 'SET_CATEGORIES_DATA'

export const SET_UA = 'SET_UA'
export const SET_UA_DATA = 'SET_UA_DATA'

export const SET_PDF = 'SET_PDF'
export const SET_PDF_DATA = 'SET_PDF_DATA'

export const SET_COORDENATES = 'SET_COORDENATES'
export const SET_COORDENATES_DATA = 'SET_COORDENATES_DATA'

export const SET_INFRASTRUCTURE = 'SET_INFRASTRUCTURE'
export const SET_INFRASTRUCTURE_DATA = 'SET_INFRASTRUCTURE_DATA'

export const SET_IRUDIZ = 'SET_IRUDIZ'
export const SET_IRUDIZ_DATA = 'SET_IRUDIZ_DATA'

export const SET_MAPS = 'SET_MAPS'
export const SET_MAPS_DATA = 'SET_MAPS_DATA'

export const SET_MAP = 'SET_MAP'
export const SET_MAP_DATA = 'SET_MAP_DATA'

export const SET_HARTU = 'SET_HARTU'
export const SET_HARTU_DATA = 'SET_HARTU_DATA'

export const SET_HARTU_FACILITY = 'SET_HARTU_FACILITY'
export const SET_HARTU_FACILITY_DATA = 'SET_HARTU_FACILITY_DATA'

export const SET_HARTU_AVAILABLE_FACILITIES_DATE_TYPE =
    'SET_HARTU_AVAILABLE_FACILITIES_DATE_TYPE'
export const SET_HARTU_AVAILABLE_FACILITIES_DATE_TYPE_DATA =
    'SET_HARTU_AVAILABLE_FACILITIES_DATE_TYPE_DATA'

export const SET_HARTU_AVAILABLE_DATES_DATE_TYPE =
    'SET_HARTU_AVAILABLE_DATES_DATE_TYPE'
export const SET_HARTU_AVAILABLE_DATES_DATE_TYPE_DATA =
    'SET_HARTU_AVAILABLE_DATES_DATE_TYPE_DATA'

export const SET_HARTU_AVAILABLE_HOURS_DATE_TYPE =
    'SET_HARTU_AVAILABLE_HOURS_DATE_TYPE'
export const SET_HARTU_AVAILABLE_HOURS_DATE_TYPE_DATA =
    'SET_HARTU_AVAILABLE_HOURS_DATE_TYPE_DATA'

export const SET_HARTU_FACILITY_BOOKING_SUBMITTING =
    'SET_HARTU_FACILITY_BOOKING_SUBMITTING'
export const SET_HARTU_FACILITY_BOOKING_SUCCESS =
    'SET_HARTU_FACILITY_BOOKING_SUCCESS'
export const SET_HARTU_FACILITY_BOOKING_ERROR =
    'SET_HARTU_FACILITY_BOOKING_ERROR'
export const SET_HARTU_FACILITY_BOOKING_ERROR_TIME_EXPIRED =
    'SET_HARTU_FACILITY_BOOKING_ERROR_TIME_EXPIRED'
export const SET_HARTU_FACILITY_BOOKING_ERROR_CONCURRENCE =
    'SET_HARTU_FACILITY_BOOKING_ERROR_CONCURRENCE'
export const SET_HARTU_FACILITY_BOOKING_RESET =
    'SET_HARTU_FACILITY_BOOKING_RESET'

export const SET_HARTU_USER_BOOKINGS = 'SET_HARTU_USER_BOOKINGS'
export const SET_HARTU_USER_BOOKINGS_DATA = 'SET_HARTU_USER_BOOKINGS_DATA'
export const SET_USER_UZ_INFO = 'SET_USER_UZ_INFO'
export const SET_USER_UZ_INFO_DATA = 'SET_USER_UZ_INFO_DATA'

export const SET_USER_UZ_UPDATE_SUBMITTING = 'SET_USER_UZ_UPDATE_SUBMITTING'
export const SET_USER_UZ_UPDATE_SUCCESS = 'SET_USER_UZ_UPDATE_SUCCESS'
export const SET_USER_UZ_UPDATE_ERROR = 'SET_USER_UZ_UPDATE_ERROR'

export const SET_INSTANCES_LIST = 'SET_INSTANCES_LIST'
export const SET_INSTANCES_LIST_DATA = 'SET_INSTANCES_LIST_DATA'

export const SET_MI_PAGO_PAY_NOW = 'SET_MI_PAGO_PAY_NOW'

export const SET_BOOKING_THANK_FAIL_DATA = 'SET_BOOKING_THANK_FAIL_DATA'
