import React, { useEffect } from 'react'
import { FailWithCircle } from '../../assets/svg/hartu'
import './FailCancelPage.css'
import { useDispatch, useSelector } from 'react-redux'
import { getBookingData } from '../../store/actions'
import t from '../../i18n/translations/translations'
import { getCookie } from '../../utils/cookiesFunctions'

const handleNavigationBookings = () => {
    let slug = window.location.pathname
    let lang = slug.split('/')[1]
    let hostname = window.location.hostname;
    let port = (hostname === 'localhost') ? 'localhost:3000' : hostname;

    if (lang === 'es') {
        slug = 'reservas'
    } else {
        slug = 'erreserbak'
    }
    window.location.href =
        window.location.protocol +
        '//' +
        port +
        '/' +
        lang +
        '/' +
        slug
}

const handleNavigationHome = () => {
    let slug = window.location.pathname
    let lang = slug.split('/')[1]
    let hostname = window.location.hostname;
    let port = (hostname === 'localhost') ? 'localhost:3000' : hostname;

    window.location.href =
        window.location.protocol + '//' + window.location.hostname + '/' + lang
}

function FailCancelPage({ match }) {
    const CANCEL_TIME_EXPIRED = 607;
    const BOOKING_NOT_FOUND = 608;
    const UNABLE_FIND_INFO_BOOKING = 609;
    const searchParams = new URLSearchParams(
        window.location.href.split(`?`)[
            window.location.href.split(`?`).length - 1
        ]
    )
    const bookingId = searchParams.get('booking')
    const dispatch = useDispatch()
    const sid = getCookie('SID') ?? null

    useEffect(() => {
        const getBookingInfo = (payload) => dispatch(getBookingData(payload))
        getBookingInfo({ bookingId })
    }, [dispatch])

    const { lang } = match
    const { facilityName, date, bookingCode, bookingErrorCode, price } = useSelector(
        ({ hartu }) => ({
            facilityName:
                lang == 'es'
                    ? hartu?.booking?.data?.data?.facility_name_es
                    : hartu?.booking?.data?.data?.facility_name_eu,
            date: hartu?.booking?.data?.data?.start_date_time,
            bookingCode: hartu?.booking?.data?.data?.booking_code,
            bookingErrorCode: hartu?.booking?.data?.code,
            price: hartu?.booking?.data?.data?.total_prize,
        })
    )

    let cancelText = '';

    if (bookingErrorCode == CANCEL_TIME_EXPIRED) {
        cancelText = t.error_cancelation_no_booking_time[lang];
    } else if (bookingErrorCode == BOOKING_NOT_FOUND) {
        cancelText = t.error_cancelation_no_booking[lang];
    } else if (bookingErrorCode == UNABLE_FIND_INFO_BOOKING) {
        cancelText = t.error_cancelation_no_booking[lang];
    } else {
        cancelText = t.cancel_booking_fail[lang];
    }

    return (
        <>
            <div className="failPage">
                <div className="failPage__card">
                    <FailWithCircle />
                    <h1>{cancelText}</h1>
                </div>
            </div>
            {sid ? (
                <button
                    className="failPage__bookings"
                    onClick={handleNavigationBookings}
                >
                    <p>
                        <i className="fa fa-list-ul" aria-hidden="true"></i>
                        <span>{t.booking_list[lang]}</span>
                    </p>
                </button>
            ) : (
                <button
                    className="failPage__bookings"
                    onClick={handleNavigationHome}
                >
                    <p>
                        <i className="fa fa-home" aria-hidden="true"></i>
                        <span>{t.home[lang]}</span>
                    </p>
                </button>
            )}
        </>
    )
}

export default FailCancelPage
