import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PageRow from '../../atoms/PageRow/PageRow'
import HartuPageIntro from '../../components/Hartu/HartuPageIntro/HartuPageIntro'
import ThankYouCancelPage from '../../compositions/ThankYouCancelPage/ThankYouCancelPage'
import FailCancelPage from '../../compositions/FailCancelPage/FailCancelPage'
import STATES from '../../constants/states'
import HartuCancelationForm from '../../forms/HartuCancelationForm/HartuCancelationForm'
import HartuCancelationFormHourFilter from '../../forms/HartuCancelationForm/HartuCancelationFormHourFilter'
import t from '../../i18n/translations/translations'
import {
    cancelHartuFacilityBooking as cancelHartuFacilityBookingAction,
    setSelectedHartu as setSelectedHartuAction,
} from '../../store/actions'
import './HartuCancelationContainer.css'

function HartuCancelationContainer({ match }) {
    const [selectedForm, setSelectedForm] = useState('HartuCancelationForm')

    const dispatch = useDispatch()

    const {
        bookingFormState,
        instanceID,
        facilities,
        facilityName,
        date,
        bookingCode,
    } = useSelector((state) => ({
        bookingFormState: state.hartu.bookingFormState,
        instanceID: state.pages.hartu?.data[0]?.idinstancia,
        facilities: state.hartu.facilities,
        facilityName: state.hartu.booking?.data?.data?.facility_name,
        date: state.hartu.booking?.data?.data?.date,
        bookingCode: state.hartu.booking?.data?.data?.booking_code,
    }))

    const cancelHartuFacilityBooking = (payload) =>
        dispatch(cancelHartuFacilityBookingAction(payload))
    const setSelectedHartu = (payload) =>
        dispatch(setSelectedHartuAction(payload))

    useEffect(() => {
        const { lang } = match

        if (instanceID && lang) {
            setSelectedHartu({
                instanceID,
                lang,
            })
        }
    }, [])

    const handleSubmit = (formData) => cancelHartuFacilityBooking(formData)

    const handleFormChange = (event) => setSelectedForm(event.target.value)

    const { lang } = match

    return bookingFormState === STATES.SUCCESS ? (
        <ThankYouCancelPage
            match = {match}
        >
        </ThankYouCancelPage>
    ) : bookingFormState === STATES.ERROR ? (
        <FailCancelPage
            match = {match}
        >
        </FailCancelPage>
    ) : (
        <div className="hartuCancelationContainer">
            <HartuPageIntro title={t.booking_cancelation_title[lang]}>
                <PageRow>
                    <p>{t.booking_cancelation_description[lang]}</p>
                </PageRow>
            </HartuPageIntro>

            <PageRow>
                <div className="radio-container">
                    <label
                        className="radio-label"
                        htmlFor="HartuCancelationForm"
                    >
                        <input
                            checked={selectedForm === 'HartuCancelationForm'}
                            id="HartuCancelationForm"
                            type="radio"
                            onChange={handleFormChange}
                            value="HartuCancelationForm"
                        />
                        <p className="radio-text">
                            {
                                t.booking_cancelation_radio_reservation_number[
                                    lang
                                ]
                            }
                        </p>
                    </label>
                    <label
                        className="radio-label"
                        htmlFor="HartuCancelationFormHourFilter"
                    >
                        <input
                            checked={
                                selectedForm ===
                                'HartuCancelationFormHourFilter'
                            }
                            id="HartuCancelationFormHourFilter"
                            onChange={handleFormChange}
                            type="radio"
                            value="HartuCancelationFormHourFilter"
                        />
                        <p className="radio-text">
                            {t.booking_cancelation_radio_date[lang]}
                        </p>
                    </label>
                </div>
                {selectedForm === 'HartuCancelationForm' && (
                    <p className="info-container">
                        {t.booking_cancelation_description[lang]}
                    </p>
                )}
                {selectedForm === 'HartuCancelationFormHourFilter' && (
                    <p className="info-container">
                        {t.booking_cancelation_description_hour[lang]}
                    </p>
                )}
                {selectedForm === 'HartuCancelationForm' && (
                    <HartuCancelationForm
                        instanceID={instanceID}
                        lang={lang}
                        state={bookingFormState}
                        handleSubmit={handleSubmit}
                    />
                )}
                {selectedForm === 'HartuCancelationFormHourFilter' && (
                    <HartuCancelationFormHourFilter
                        instanceID={instanceID}
                        lang={lang}
                        state={bookingFormState}
                        facilities={facilities}
                        handleSubmit={handleSubmit}
                    />
                )}
            </PageRow>
        </div>
    )
}

export default HartuCancelationContainer
