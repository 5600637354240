import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import HartuCancelationButton from '../../atoms/HartuCancelationButton/HartuCancelButton'
import PageRow from '../../atoms/PageRow/PageRow'
import SearchInput from '../../atoms/SearchInput/SearchInput'
import TimePicker from '../../atoms/TimePicker/TimePicker'
import CalendarModal from '../../components/CalendarModal/CalendarModal'
import HartuCategorySelector from '../../components/Hartu/HartuCategorySelector/HartuCategorySelector'
import HartuFacilityGrid from '../../components/Hartu/HartuFacilityGrid/HartuFacilityGrid'
import HartuPageIntro from '../../components/Hartu/HartuPageIntro/HartuPageIntro'
import HartuRemoveFiltersButton from '../../components/Hartu/HartuRemoveFiltersButton/HartuRemoveFiltersButton'
import HartuSearchInput from '../../components/Hartu/HartuSearchInput/HartuSearchInput'
import BODY_OVERFLOW_HIDDEN from '../../constants/styles'
import t from '../../i18n/translations/translations'
import { setSelectedHartu as setSelectedHartuAction } from '../../store/actions'
import hartuPageDataTransformer from '../../transformers/hartuPageDataTransformer'
import './HartuContainer.css'

function HartuContainer({ match }) {
    const dispatch = useDispatch()
    const {
        categories,
        facilitiesLoading,
        facilitiesAll,
        instanceID,
        pageData,
    } = useSelector(({ page, hartu, pages }) => ({
        categories: hartu?.hartu?.data?.categories,
        facilitiesLoading: hartu?.facilitiesLoading,
        facilitiesAll: hartu?.facilities,
        hartu: hartu?.hartu,
        instanceID: pages?.hartu?.data?.[0]?.idinstancia,
        page,
        pageData: pages?.hartu?.data?.[0],
    }))

    const setSelectedHartu = (payload) =>
        dispatch(setSelectedHartuAction(payload))

    const { lang } = match
    const [isModalOpened, setIsModalOpened] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [selectedDate, setSelectedDate] = useState(null)
    const [selectedStartTime, setSelectedStartTime] = useState(null)
    const [selectedFinishTime, setSelectedFinishTime] = useState(null)
    const [searchValue, setSearchValue] = useState(null)

    const filterByCategory = (filteredFacilities) =>
        filteredFacilities?.filter(
            (facility) => facility.facility_type === selectedCategory
        )

    const filterByDate = (filteredFacilities) =>
        filteredFacilities?.filter((facility) => facility.dates[selectedDate])

    const filterByHour = (filteredFacilities) =>
        filteredFacilities?.filter((facility) =>
            facility.dates[selectedDate].some(
                (date) =>
                    (!selectedStartTime ||
                        selectedStartTime <= date.start_date) &&
                    (!selectedFinishTime ||
                        selectedFinishTime >= date.start_date)
            )
        )

    const filterBySearchValue = (filteredFacilities) =>
        filteredFacilities?.filter(
            (facility) =>
                facility.name.es.toLowerCase().includes(searchValue) ||
                facility.name.eu.toLowerCase().includes(searchValue)
        )

    const START_TIME = 'startTime'

    const FINISH_TIME = 'finishTime'

    useEffect(() => {
        if (instanceID && lang) {
            setSelectedHartu({
                instanceID,
                lang,
            })
        }
    }, [instanceID, lang])

    const handleModal = () => {
        setIsModalOpened(!isModalOpened)
    }

    const handleTimeChange = (event) => {
        const selectedTimeValue = event.target.value
        const selectedTimeId = event.target.id

        if (selectedTimeId === START_TIME) {
            setSelectedStartTime(selectedTimeValue || null)
        }

        if (selectedTimeId === FINISH_TIME) {
            setSelectedFinishTime(selectedTimeValue || null)
        }
    }

    const handleTimeLostFocus = (event) => {
        const eventTarget = event.target
        const selectedTime = eventTarget.value

        if (!selectedTime) {
            eventTarget.value = null
        }
    }

    const handleSearchChange = (event) => {
        const searchValueEvent = event.target.value.toLowerCase()

        setSearchValue(searchValueEvent)
    }

    const handleRemoveFilters = () => {
        setSelectedCategory(null)
        setSelectedDate(null)
        setSearchValue(null)
        setSelectedStartTime(null)
        setSelectedFinishTime(null)
    }
    
    const filterFacilities = () => {
        let filteredFacilities = [...facilitiesAll]

        if (searchValue) {
            filteredFacilities = filterBySearchValue(filteredFacilities)
        }
        if (selectedCategory) {
            filteredFacilities = filterByCategory(filteredFacilities)
        }
        if (selectedDate) {
            filteredFacilities = filterByDate(filteredFacilities)
        }

        if (selectedStartTime || selectedFinishTime) {
            filteredFacilities = filterByHour(filteredFacilities)
        }

        return filteredFacilities
    }

    const updateBreadcrumbLink = () => {
        const breadcrumbElement = document.querySelector('.breadcrumb .active')
        const linkText = breadcrumbElement.innerHTML

        breadcrumbElement.innerHTML = `<a href="">${linkText}</a>`
    }

    const filterFacilitiesByCategory = (category) => {
        updateBreadcrumbLink()
        setSelectedCategory(category)
    }

    const selectDate = (date) => {
        setSelectedDate(date.format('YYYY-MM-DD'))

        if (date) {
            handleModal()
            document.body.classList.remove(BODY_OVERFLOW_HIDDEN)
        }
    }

    const filteredFacilities = facilitiesAll && filterFacilities()

    const availableDates = Array.from(
        new Set(
            filteredFacilities?.reduce((acc, facility) => {
                const datesObject = facility.dates
                const datesArray = Object.keys(datesObject)
                return acc.concat(datesArray)
            }, [])
        )
    )

    const pageIntroData = pageData && hartuPageDataTransformer(pageData)
    const selectedCategoryObject = categories?.find(
        (category) => category.id === selectedCategory
    )
    
    return (
        <div className="hartuContainer">
            <div
                className={classNames('hartuContainer__top ', {
                    'hartuContainer__top--tall': selectedCategory,
                })}
            >
                <HartuPageIntro title={pageIntroData?.titulo}>
                    <PageRow>
                        {pageIntroData && (
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: pageIntroData.descripcion,
                                }}
                            />
                        )}
                    </PageRow>
                </HartuPageIntro>

                <PageRow>
                    <div className="hartuContainer__intro__subtitle">
                        {!selectedCategory && (
                            <p>{t.category_selector_title[lang]}</p>
                        )}
                        {selectedCategoryObject && (
                            <p>
                                {t.category_selected[lang]}{' '}
                                {lang === 'eu'
                                    ? selectedCategoryObject.name.eu
                                    : selectedCategoryObject.name.es}
                            </p>
                        )}
                    </div>
                    <div className="flex-items">
                        <HartuCategorySelector
                            action={filterFacilitiesByCategory}
                            categories={categories}
                            lang={lang}
                            selectedCategory={selectedCategory}
                        />

                        <SearchInput
                            initialData={filteredFacilities}
                            onChange={handleSearchChange}
                            placeholder={t.filter_selector[lang]}
                        />

                        <HartuSearchInput
                            lang={lang}
                            selectedDate={selectedDate}
                            openModal={handleModal}
                        />

                        {selectedDate && (
                            <>
                                <TimePicker
                                    id={START_TIME}
                                    name={START_TIME}
                                    onBlur={handleTimeLostFocus}
                                    onChange={handleTimeChange}
                                />
                                <TimePicker
                                    id={FINISH_TIME}
                                    name={FINISH_TIME}
                                    onBlur={handleTimeLostFocus}
                                    onChange={handleTimeChange}
                                />
                            </>
                        )}

                        <HartuRemoveFiltersButton
                            onClick={handleRemoveFilters}
                            lang={lang}
                        />
                    </div>
                </PageRow>
            </div>
            <PageRow className="hartuContainer__bottom">
                <HartuFacilityGrid
                    isLoading={facilitiesLoading}
                    selectedDate={selectedDate}
                    categories={categories}
                    data={filteredFacilities}
                    lang={lang}
                />
                <HartuCancelationButton lang={lang} />
            </PageRow>
            <CalendarModal
                isOpened={isModalOpened}
                availableDates={availableDates}
                handleModal={handleModal}
                selectDate={selectDate}
                lang={lang}
            />
        </div>
    )
}

export default HartuContainer
