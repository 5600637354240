import React from 'react'
import PageTitle from '../../../atoms/PageTitle/PageTitle'
import PageRow from '../../../atoms/PageRow/PageRow'
import './HartuPageIntro.css'

function HartuPageIntro({ children, title }) {
    return (
        <section className="hartuContainer__intro">
            <div className="hartuContainer__intro__title">
                <PageRow>
                    <PageTitle text={title} />
                </PageRow>
            </div>
            {children}
        </section>
    )
}

export default HartuPageIntro
