import STATES from '../../constants/states'
import {
    SET_HARTU,
    SET_HARTU_AVAILABLE_DATES_DATE_TYPE,
    SET_HARTU_AVAILABLE_DATES_DATE_TYPE_DATA,
    SET_HARTU_AVAILABLE_FACILITIES_DATE_TYPE,
    SET_HARTU_AVAILABLE_FACILITIES_DATE_TYPE_DATA,
    SET_HARTU_AVAILABLE_HOURS_DATE_TYPE,
    SET_HARTU_AVAILABLE_HOURS_DATE_TYPE_DATA,
    SET_HARTU_DATA,
    SET_HARTU_FACILITY,
    SET_HARTU_FACILITY_BOOKING_ERROR,
    SET_HARTU_FACILITY_BOOKING_ERROR_CONCURRENCE,
    SET_HARTU_FACILITY_BOOKING_ERROR_TIME_EXPIRED,
    SET_HARTU_FACILITY_BOOKING_RESET,
    SET_HARTU_FACILITY_BOOKING_SUBMITTING,
    SET_HARTU_FACILITY_BOOKING_SUCCESS,
    SET_HARTU_FACILITY_DATA,
    SET_HARTU_USER_BOOKINGS,
    SET_HARTU_USER_BOOKINGS_DATA,
    SET_INSTANCES_LIST,
    SET_INSTANCES_LIST_DATA,
    SET_USER_UZ_INFO,
    SET_USER_UZ_INFO_DATA,
    SET_USER_UZ_UPDATE_ERROR,
    SET_USER_UZ_UPDATE_SUBMITTING,
    SET_USER_UZ_UPDATE_SUCCESS,
    SET_MI_PAGO_PAY_NOW,
    SET_BOOKING_THANK_FAIL_DATA
} from '../constants/constants'

const initialState = {
    facilities: [],
    facility: null,
    dates: null,
    hours: null,
    bookingFormState: STATES.DEFAULT,
    booking: null,
    hoursLoading: false,
    datesLoading: false,
    facilitiesLoading: false,
    id_mipago: null,
    payment_back: null
}

// eslint-disable-next-line default-param-last
const hartu = (state = initialState, action) => {
    switch (action.type) {
        case SET_HARTU:
            return {
                ...state,
                hartu: action.payload,
                facilitiesLoading: true,
                facilities: [],
            }

        case SET_HARTU_DATA:
            return {
                ...state,
                hartu: action.payload,
                facilitiesLoading: false,
                facilities: action.payload.data.data,
            }

        case SET_HARTU_FACILITY:
            return {
                ...state,
                facilitiesLoading: true,
                facility: action.payload,
            }

        case SET_HARTU_FACILITY_DATA:
            return {
                ...state,
                facilitiesLoading: false,
                facility: action.payload,
            }

        case SET_HARTU_AVAILABLE_FACILITIES_DATE_TYPE:
            return {
                ...state,
                facilitiesLoading: true,
                facilitiesByType: [],
            }

        case SET_HARTU_AVAILABLE_FACILITIES_DATE_TYPE_DATA:
            return {
                ...state,
                facilitiesLoading: false,
                facilitiesByType: action.payload.data,
            }

        case SET_HARTU_AVAILABLE_DATES_DATE_TYPE:
            return {
                ...state,
                datesLoading: true,
                dates: action.payload,
            }

        case SET_HARTU_AVAILABLE_DATES_DATE_TYPE_DATA:
            return {
                ...state,
                datesLoading: false,
                dates: action.payload,
            }

        case SET_HARTU_AVAILABLE_HOURS_DATE_TYPE:
            return {
                ...state,
                hoursLoading: true,
                hours: action.payload,
            }

        case SET_HARTU_AVAILABLE_HOURS_DATE_TYPE_DATA:
            return {
                ...state,
                hoursLoading: false,
                hours: action.payload,
            }
        case SET_HARTU_FACILITY_BOOKING_RESET:
            return {
                ...state,
                bookingFormState: STATES.DEFAULT,
                booking: action.payload,
            }
        case SET_HARTU_FACILITY_BOOKING_SUBMITTING:
            return {
                ...state,
                bookingFormState: STATES.SUBMITTING,
                booking: action.payload,
            }
        case SET_HARTU_FACILITY_BOOKING_SUCCESS:
            return {
                ...state,
                bookingFormState: STATES.SUCCESS,
                booking: action.payload,
            }
        case SET_HARTU_FACILITY_BOOKING_ERROR:
            return {
                ...state,
                booking: action.payload,
                bookingFormState: STATES.ERROR,
            }
        case SET_HARTU_FACILITY_BOOKING_ERROR_TIME_EXPIRED:
            return {
                ...state,
                bookingFormState: STATES.ERROR_TIME_EXPIRED,
                booking: action.payload,
            }
        case SET_HARTU_FACILITY_BOOKING_ERROR_CONCURRENCE:
            return {
                ...state,
                bookingFormState: STATES.ERROR_CONCURRENCE,
                booking: action.payload,
            }
        case SET_HARTU_USER_BOOKINGS:
            return {
                ...state,
                bookingsLoading: true,
                booking: action.payload,
            }
        case SET_HARTU_USER_BOOKINGS_DATA:
            return {
                ...state,
                bookingsLoading: false,
                bookingList: action.payload,
            }
        case SET_USER_UZ_INFO:
            return {
                ...state,
                infoLoading: true,
                userInfo: action.payload,
            }
        case SET_USER_UZ_INFO_DATA:
            return {
                ...state,
                infoLoading: false,
                userInfo: action.payload,
            }
        case SET_USER_UZ_UPDATE_SUBMITTING:
            return {
                ...state,
                userFormState: STATES.SUBMITTING,
                user: action.payload,
            }
        case SET_USER_UZ_UPDATE_SUCCESS:
            return {
                ...state,
                userFormState: STATES.SUCCESS,
                user: action.payload,
            }
        case SET_USER_UZ_UPDATE_ERROR:
            return {
                ...state,
                userFormState: STATES.ERROR,
                user: action.payload,
            }
        case SET_INSTANCES_LIST:
            return {
                ...state,
                instancesLoading: true,
                instancesList: action.payload,
            }
        case SET_INSTANCES_LIST_DATA:
            return {
                ...state,
                instancesLoading: false,
                instancesList: action.payload,
            }
        case SET_MI_PAGO_PAY_NOW:
            return {
                ...state,
                id_mipago: action.payload,
                payment_back: action.payload,
            }
        case SET_BOOKING_THANK_FAIL_DATA:
            return {
                ...state,
                booking: action.payload
            }
        default:
            return state
    }
}

export default hartu
