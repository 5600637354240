import React, { useState } from 'react'
import './Tooltip.css'
import t from '../../i18n/translations/translations'

const Tooltip = ({ value, lang }) => {
    const [showTooltip, setShowTooltip] = useState(false)

    const handleCopy = () => {
        navigator.clipboard.writeText(value).then(() => {
            setShowTooltip(true)
            setTimeout(() => setShowTooltip(false), 2000)
        })
    }

    return (
        <div
            className="tooltip-container"
            onMouseLeave={() => setShowTooltip(false)} 
        >
            <span
                className={`tooltip ${showTooltip ? 'show' : ''}`}
            >
                  {t.booking_code_tooltip[lang]}
            </span>
            <span className="tooltip-text" onClick={handleCopy}>
                {value}
            </span>
        </div>
    )
}

export default Tooltip
