import React from 'react'

export default function IconPay() {
    return (
        <svg
            width="24"
            height="16"
            viewBox="0 0 24 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.655"
                y="0.655"
                width="22.69"
                height="14.69"
                rx="2.345"
                stroke="var(--color-main)"
                stroke-width="1.31"
            />
            <rect
                x="1.04297"
                y="4.17383"
                width="21.913"
                height="3.13043"
                fill="var(--color-main)"
            />
        </svg>
    )
}
