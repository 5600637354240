import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { OkWithCircle } from '../../assets/svg/hartu'
import {getBookingData} from '../../store/actions'
import './ThankYouCancelPage.css'
import t from '../../i18n/translations/translations'
import { getCookie } from '../../utils/cookiesFunctions'

const handleNavigationBookings = () => {
    let slug = window.location.pathname
    let lang = slug.split('/')[1]
    let hostname = window.location.hostname;
    let port = (hostname === 'localhost') ? 'localhost:3000' : hostname;

    if (lang === 'es') {
        slug = 'reservas'
    } else {
        slug = 'erreserbak'
    }
    window.location.href =
        window.location.protocol +
        '//' +
        port +  
        '/' +
        lang +
        '/' +
        slug
}

const handleNavigationHome = () => {
    let slug = window.location.pathname
    let lang = slug.split('/')[1]
    let hostname = window.location.hostname;
    let port = (hostname === 'localhost') ? 'localhost:3000' : hostname;

    window.location.href =
        window.location.protocol +
        '//' +
        port +
        '/' +
        lang 
}

function ThankYouCancelPage({match}) {
    const searchParams = new URLSearchParams(window.location.href.split(`?`)[window.location.href.split(`?`).length - 1]);
    const bookingId = searchParams.get('booking');
    const sid = getCookie('SID')??null;

    const dispatch = useDispatch();

    useEffect(() => {
        const getBookingInfo = (payload) => dispatch(getBookingData(payload))
        getBookingInfo({ bookingId })
    }, [dispatch])

    const { lang } = match
    const { facilityName, date, bookingCode, price } = useSelector(
            ({ hartu }) => ({
                facilityName: (lang == 'es') ? hartu?.booking?.data?.data?.facility_name_es : hartu?.booking?.data?.data?.facility_name_eu,
                date: hartu?.booking?.data?.data?.start_date_time,
                bookingCode: hartu?.booking?.data?.data?.booking_code,
                price: hartu?.booking?.data?.data?.total_prize,
            })
        )

    return (
        <>
            <div className="thankYouPage">
                <div className="thankYouCancelPage__card">
                    <OkWithCircle />
                    <h1>{t.cancel_success_thank_you[lang]}</h1>
                    <div className="thankYouPage__container">
                        <ul>
                            <li>
                                <p className="thankYouPage__container__subtitle">{t.thank_you_id[lang]}</p>
                                <p className="thankYouPage__container__subtitle_info">{bookingCode}</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            { sid ? 
                <button
                    className="thankYouPage__bookings"
                    onClick={handleNavigationBookings}
                >
                    <p>
                        <i className="fa fa-list-ul" aria-hidden="true"></i>
                        <span>{t.booking_list[lang]}</span>
                    </p>
                </button>
                :
                <button
                    className="thankYouPage__bookings"
                    onClick={handleNavigationHome}
                >
                    <p>
                        <i className="fa fa-home" aria-hidden="true"></i>
                        <span>{t.home[lang]}</span>
                    </p>
                </button>
            } 
        </>
    )
}

export default ThankYouCancelPage
