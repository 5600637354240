import React from 'react'
import PageRow from '../../atoms/PageRow/PageRow'
import FailPage from '../../compositions/FailPage/FailPage'
import './HartuFailPageContainer.css'

function HartuFailPageContainer({ match }) {

    return (
        <div className="hartuFailPageContainer">
            <PageRow>
                <FailPage
                    match={match}
                >
                </FailPage>
            </PageRow>
        </div>
    )
}

export default HartuFailPageContainer
