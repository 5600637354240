import moment from 'moment'
import React from 'react'
import { CalendarIcon } from '../../../assets/svg/hartu'
import PageRow from '../../../atoms/PageRow/PageRow'
import { DATE_FORMAT } from '../../../constants'
import t from '../../../i18n/translations/translations'
import './HartuSearchInput.css'

function HartuSearchInput({ disabled, lang, selectedDate, openModal }) {
    const date =
        selectedDate && lang === 'es'
            ? moment(selectedDate).format(DATE_FORMAT)
            : selectedDate

    return (
        <section className="hartuSearchInput">
            <PageRow>
                <button
                    aria-haspopup="true"
                    disabled={disabled}
                    className={`hartuSearchInput__button${
                        selectedDate ? ' active' : ''
                    }`}
                    onClick={() => openModal()}
                    type="button"
                >
                    <CalendarIcon />
                    {date || t.see_calendar[lang]}
                </button>
            </PageRow>
        </section>
    )
}

export default HartuSearchInput
