import React from 'react'
import PageRow from '../../atoms/PageRow/PageRow'
import ThankYouPage from '../../compositions/ThankYouPage/ThankYouPage'
import './HartuThankYouPageContainer.css'

function HartuThankYouPageContainer({ match }) {

    return (
        <div className="hartuThankYouPageContainer">
            <PageRow>
                <ThankYouPage
                    match={match}
                >
                </ThankYouPage>
            </PageRow>
        </div>
    )
}

export default HartuThankYouPageContainer
