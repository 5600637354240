import hartuCategoriesTransformer from './hartuCategoriesTransformer'
import transformFacility from '../transformFacility'

const transformFacilities = data => {
    const facilitiesTransformed = data.map(facility =>
        transformFacility({ data: facility })
    )

    return facilitiesTransformed
}

const hartuDataTransformer = data => {
    const dataFacility = data?.data?.facilities
    const facilitiesWithDates = dataFacility.filter(
        facility => Object.values(facility.dates).length
    )
    const dataTransformed = {
        code: data.code,
        error: data.error,
        categories: data?.data?.type && hartuCategoriesTransformer(data.data.type),
        data: dataFacility && transformFacilities(facilitiesWithDates),
    }

    return dataTransformed
}

export default hartuDataTransformer