import React, { useEffect } from 'react'
import { FailWithCircle } from '../../assets/svg/hartu'
import './FailPage.css'
import { useDispatch, useSelector } from 'react-redux'
import { getBookingData } from '../../store/actions'
import t from '../../i18n/translations/translations'
import { getCookie } from '../../utils/cookiesFunctions'

const handleNavigationBookings = () => {
    let slug = window.location.pathname
    let lang = slug.split('/')[1]
    let hostname = window.location.hostname
    let port = hostname === 'localhost' ? 'localhost:3000' : hostname

    if (lang === 'es') {
        slug = 'reservas'
    } else {
        slug = 'erreserbak'
    }
    window.location.href =
        window.location.protocol + '//' + port + '/' + lang + '/' + slug
}

const handleNavigationHome = () => {
    let slug = window.location.pathname
    let lang = slug.split('/')[1]
    let hostname = window.location.hostname
    let port = hostname === 'localhost' ? 'localhost:3000' : hostname

    window.location.href = window.location.protocol + '//' + port + '/' + lang
}

function FailPage({ match }) {
    const searchParams = new URLSearchParams(
        window.location.href.split(`?`)[
            window.location.href.split(`?`).length - 1
        ]
    )
    const bookingId = searchParams.get('booking');
    const isApp = searchParams.get('apps');
    const dispatch = useDispatch();
    const sid = getCookie('SID') ?? null
    const base64Response = {
        'pago_realizado': false
    };

    useEffect(() => {
        const getBookingInfo = (payload) => dispatch(getBookingData(payload))
        getBookingInfo({ bookingId })
    }, [dispatch])

    const { lang } = match
    const { facilityName, date, bookingCode, price } = useSelector(
        ({ hartu }) => ({
            facilityName:
                lang == 'es'
                    ? hartu?.booking?.data?.data?.facility_name_es
                    : hartu?.booking?.data?.data?.facility_name_eu,
            date: hartu?.booking?.data?.data?.start_date_time,
            bookingCode: hartu?.booking?.data?.data?.booking_code,
            price: hartu?.booking?.data?.data?.total_prize,
        })
    )

    return (
        <>
            <div className="failPage">
                <div className="failPage__card">
                    <FailWithCircle />
                    <h1>
                        {t.booking_fail[lang]}
                        <br />
                        {t.booking_fail_2[lang]}
                    </h1>
                    <div className="failPage__container">
                        <ul>
                            <li>
                                <p className="failPage__container__subtitle">
                                    {t.thank_you_instalation_name[lang]}
                                </p>
                                <p className="failPage__container__subtitle_info">
                                    {facilityName}
                                </p>
                            </li>
                            <li>
                                <p className="failPage__container__subtitle">
                                    {t.thank_you_reservation_date[lang]}
                                </p>
                                <p className="failPage__container__subtitle_info">
                                    {date}
                                </p>
                            </li>
                            <li>
                                <p className="failPage__container__subtitle">
                                    {t.thank_you_id[lang]}
                                </p>
                                <p className="failPage__container__subtitle_info">
                                    {bookingCode}
                                </p>
                            </li>
                            <li>
                                <p className="failPage__container__subtitle">
                                    {t.total_prize[lang]}
                                </p>
                                <p className="failPage__container__subtitle_info">
                                    {price} €
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {!isApp ?
                (sid ? 
                    <button
                        className="thankYouPage__bookings"
                        onClick={handleNavigationBookings}
                    >
                        <p>
                            <i className="fa fa-list-ul" aria-hidden="true"></i>
                            <span>{t.booking_list[lang]}</span>
                        </p>
                    </button>
                    :
                    <button
                        className="thankYouPage__bookings"
                        onClick={handleNavigationHome}
                    >
                        <p>
                            <i className="fa fa-home" aria-hidden="true"></i>
                            <span>{t.home[lang]}</span>
                        </p>
                    </button>
                )
            : 
                <a className="thankYouPage__bookings" href={"UZ"+idInstancia+"://"+btoa(base64Response)} >
                    <i className={`fa ${sid ? "fa-list-ul" : "fa-home"}`} aria-hidden="true"></i>
                    <span>{t.go_app[lang]}</span>
                </a>
            } 
        </>
    )
}

export default FailPage
