const transformFacility = ({ data }) => ({
    available: data.available,
    available_days_message: {
        es: data.booking_date_es,
        eu: data.booking_date_eu,
    },
    booking_slot: data.booking_slot,
    dates: data.dates,
    description: {
        es: data.description_es,
        eu: data.description_eu,
    },
    facility_type: data.id_facility_type,
    email: data.email,
    id: data.id_facility,
    image: data.image ? `${process.env.REACT_APP_ROOT}hartu/${data.idinstancia}/${data.image}` : null,
    name: {
        es: data.facility_name_es,
        eu: data.facility_name_eu,
    },
    pvp: data.pvp,
    text_info: {
        es: data.text_info_es,
        eu: data.text_info_eu
    },
    time_to_pay: data.time_to_pay,
    has_light: data.has_light,
    censed: data.only_for_census,
    can_be_booked: data.can_be_booked,
    disability: data.movility_reduce_adapted,
    user_prize: data.user_prize ? {
        id_user: data.user_prize.id_user,
        username: data.user_prize.name,
        dni: data.user_prize.dni,
        surnames: data.user_prize.lastname,
        email: data.user_prize.email,
        phone: data.user_prize.phone,
        postalCode: data.user_prize.cp,
        prize: data.user_prize.prize,
        lightPrize: data.user_prize.prize_with_light
    } : null
})

export default transformFacility