import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PageRow from '../../atoms/PageRow/PageRow'
import PaginatedTable from '../../components/PaginatedTable/PaginatedTable'
import t from '../../i18n/translations/translations'
import HartuCancelationForm from '../../forms/HartuCancelationForm/HartuCancelationForm'
import {
    cancelHartuFacilityBooking as cancelHartuFacilityBookingAction,
    getBookingsByUserId,
} from '../../store/actions'
import './UserBookingsContainer.css'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import MiPagoModal from '../../components/MiPagoModal/MiPagoModal'
import STATES from '../../constants/states'
import IconPay from '../../assets/svg/hartu/IconPay'
import IconCancel from '../../assets/svg/hartu/IconCancel'
import { getCookie } from '../../utils/cookiesFunctions'
import Tooltip from '../../components/Tooltip/Tooltip'

import {
    HARTU_BOOKING_STATUS_ACTIVE,
    HARTU_BOOKING_STATUS_PENDING,
} from '../../constants/hartu'

const reloadPage = () => {
    history.replaceState({}, document.title, window.location.pathname)
    window.location.reload()
}

const userId = getCookie('SID')

const renderDeleteButton = (params, lang) => {
    const dispatch = useDispatch()
    const handleSubmit = (formData) => cancelHartuFacilityBooking(formData)
    const cancelHartuFacilityBooking = (payload) => {
        dispatch(
            cancelHartuFacilityBookingAction(payload),
            setTimeout(() => {
                reloadPage()
            }, '1000')
        )
    }

    const {
        status,
        total_prize: totalPrize,
        finish_date_time: finishDateTime,
    } = params.data

    const [open, setOpen] = React.useState(false)
    const handleClickOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    const notPastBookingDate = (date) => {
        const currentDate = new Date()
        return new Date(date) > currentDate
    }

    const shouldShowButton =
        notPastBookingDate(finishDateTime) &&
        ((status === HARTU_BOOKING_STATUS_ACTIVE && totalPrize === '0') ||
            status === HARTU_BOOKING_STATUS_PENDING)

    if (shouldShowButton) {
        return (
            <>
                <Button onClick={handleClickOpen} className="payBookingButton">
                    <IconCancel />
                </Button>
                <Dialog
                    id="dialog-content"
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {t.action_cancel_booking[lang]}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {t.modal_message[lang]}
                        </DialogContentText>
                        <div id="form-content">
                            <HartuCancelationForm
                                instanceID={params.data.idinstancia}
                                lang={lang}
                                state={STATES.DEFAULT}
                                booking_code={params.data.booking_code}
                                email={params.data.email}
                                dni={params.data.dni}
                                handleSubmit={handleSubmit}
                            />
                        </div>
                    </DialogContent>
                </Dialog>
            </>
        )
    }

    return null
}

const renderPayNowButton = (params, lang) => {
    const {
        status,
        total_prize: totalPrize,
        finish_date_time: finishDateTime,
    } = params.data

    const [date, time] = params.data.booking_date.split(' ')

    const facilityName =
        lang === 'eu'
            ? params.data.facility_name_eu
            : params.data.facility_name_es

    const [openPayModal, setOpenPayModal] = useState(false)

    const handleOpen = () => setOpenPayModal(true)

    const handleClose = () => setOpenPayModal(false)

    const notPastBookingDate = (date) => {
        const currentDate = new Date()
        return new Date(date) > currentDate
    }

    const shouldShowButtonToPay =
        notPastBookingDate(finishDateTime) &&
        status === HARTU_BOOKING_STATUS_PENDING

    if (shouldShowButtonToPay) {
        return (
            <>
                <Button onClick={handleOpen} className="payBookingButton">
                    {<IconPay />}
                </Button>
                <MiPagoModal
                    id="dialog-content-mi-Pago"
                    isOpened={openPayModal}
                    handleClose={handleClose}
                    lang={lang}
                    instanceID={params.data.idinstancia}
                    id_booking={params.data.id_booking}
                    id_user={userId}
                    total_price={params.data.total_prize}
                    facilityName={facilityName}
                    bookingDate={date}
                    startBookingHour={time}
                    hidePayLaterButton={true}
                    booking_code={params.data.booking_code}
                ></MiPagoModal>
            </>
        )
    }

    return null
}

function SelectFilter({ label, lang, id, categories, onChange, value }) {
    return (
        <div>
            <label htmlFor={id}>{label}</label>
            <select id={id} onChange={onChange} value={value}>
                <option value="">{t.no_filter[lang]}</option>
                {categories?.map((category) => (
                    <option key={category.id} value={category.value}>
                        {category.name}
                    </option>
                ))}
            </select>
        </div>
    )
}

function UserBookingsContainer({ data, match }) {
    const dispatch = useDispatch()

    const { pages } = data
    const { data: pageData } = pages
    const { titulo } = pageData[0]
    const idInstancia = data.pages.data[0].idinstancia
    const { lang } = match
    const [rowData, setRowData] = useState([])
    const [filteredBookings, setFilteredBookings] = useState([])
    const [searchValue, setSearchValue] = useState('')
    const [selectedBookingStatus, setSelectedBookingStatus] = useState(0)
    const [selectedDate, setSelectedDate] = useState('')
    const [selectedFacilityType, setSelectedFacilityType] = useState('')
    const [facilityTypesCategoriesSet, setFacilityTypesCategoriesSet] =
        useState([])

    useEffect(() => {
        const getBookings = (payload) => dispatch(getBookingsByUserId(payload))
        getBookings({ lang, idInstancia })
    }, [dispatch, lang])

    const { bookingsList } = useSelector(({ hartu }) => ({
        bookingsList: hartu?.bookingList?.data,
    }))

    useEffect(() => {
        setRowData(bookingsList)
        setFilteredBookings(bookingsList)
    }, [bookingsList])

    useEffect(() => {
        const facilityTypesCategories = Array.isArray(rowData)
            ? new Set(rowData?.map((booking) => booking[`facility_name_${lang}`]))
            : new Set()

        setFacilityTypesCategoriesSet(facilityTypesCategories)
    }, [lang, rowData])

    const facilityTypesCategories = Array.from(facilityTypesCategoriesSet)?.map(
        (name, index) => ({
            id: index,
            name,
            value: name,
        })
    )

    const handleBookingStatusChange = (e) => {
        setSelectedBookingStatus(e.target.value)
    }

    const handleDateChange = (e) => {
        setSelectedDate(e.target.value)
    }

    const handleFacilityTypeChange = (e) => {
        setSelectedFacilityType(e.target.value)
    }

    const handleSearchChange = (event) => {
        const searchValueEvent = event.target.value.toLowerCase()

        setSearchValue(searchValueEvent)
    }

    const bookingStatusCategories = [
        { id: 1, name: t.active[lang], value: '1' },
        { id: 2, name: t.passed[lang], value: '2' },
        { id: 3, name: t.cancelled[lang], value: '3' },
        { id: 4, name: t.outstanding[lang], value: '4' },
    ]

    const statusMapping = bookingStatusCategories.reduce((acc, category) => {
        acc[category.value] = category.name
        return acc
    }, {})

    const columnDefs = [
        {
            headerName: t.facility_name[lang],
            field: `facility_name_${lang}`,
            flex: 1.5,
            minWidth: 250,
        },
        {
            headerName: t.booking_date[lang],
            field: 'booking_date',
            flex: 1.5,
            minWidth: 250,
            valueGetter: (params) => {
                const startDate = params.data.start_date_time
                    ? new Date(params.data.start_date_time).toLocaleDateString(
                          lang
                      )
                    : ''
                const startTime = params.data.start_date_time
                    ? new Date(params.data.start_date_time).toLocaleTimeString(
                          lang,
                          {
                              hour: '2-digit',
                              minute: '2-digit',
                          }
                      )
                    : ''
                const finishTime = params.data.finish_date_time
                    ? new Date(params.data.finish_date_time).toLocaleTimeString(
                          lang,
                          {
                              hour: '2-digit',
                              minute: '2-digit',
                          }
                      )
                    : ''
                return startDate
                    ? `${startDate} - ${startTime} - ${finishTime}`
                    : ''
            },
        },
        {
            headerName: t.booking_code[lang],
            field: 'booking_code',
            flex: 2,
            minWidth: 300,
            cellClass: 'selectable-text',
            cellRenderer: (params) => (
                <Tooltip value={params.value} lang={lang} />
            ),
        },
        {
            headerName: t.booking_status[lang],
            field: 'status',
            valueFormatter: (params) =>
                statusMapping[params.value] || params.value,
            minWidth: 180,
        },
        {
            headerName: t.total_prize[lang],
            field: 'total_prize',
            flex: 0.8,
            valueFormatter: (params) => `${params.value} €`,
            minWidth: 120,
        },
        {
            cellStyle: { textOverflow: 'unset' },
            cellRenderer: (params) => renderPayNowButton(params, lang),
            flex: 0.5,
            minWidth: 90,
        },
        {
            cellStyle: { textOverflow: 'unset' },
            cellRenderer: (params) => renderDeleteButton(params, lang),
            flex: 1,
            minWidth: 120,
        },
    ]

    const filters = [
        {
            categories: bookingStatusCategories,
            label: t.booking_status[lang],
            id: 'bookingStatus',
            onChange: handleBookingStatusChange,
            value: selectedBookingStatus,
        },
        {
            categories: facilityTypesCategories,
            label: t.facility_name[lang],
            id: 'facilityType',
            onChange: handleFacilityTypeChange,
            value: selectedFacilityType,
        },
    ]

    const filterBySearchValue = (filteredBookingsData) =>
        filteredBookingsData.filter(
            (booking) =>
                booking.booking_code.toLowerCase().includes(searchValue) ||
                booking[`facility_name_${lang}`]
                    .toLowerCase()
                    .includes(searchValue)
        )

    const filterByBookingStatus = (filteredBookingsData) =>
        filteredBookingsData.filter(
            (booking) => booking.status === +selectedBookingStatus
        )

    const filterByDate = (filteredBookingsData) =>
        filteredBookingsData.filter((booking) => {
            const bookingDate = new Date(booking.start_date_time).toDateString()
            const selectedDateObj = new Date(selectedDate).toDateString()
            return bookingDate === selectedDateObj
        })

    const filterByFacilityType = (filteredBookingsData) =>
        filteredBookingsData.filter(
            (booking) => booking[`facility_name_${lang}`] === selectedFacilityType
        )

    const filterBookings = () => {
        let filteredBookingsData = [...rowData]

        if (searchValue) {
            filteredBookingsData = filterBySearchValue(filteredBookingsData)
        }

        if (selectedBookingStatus) {
            filteredBookingsData = filterByBookingStatus(filteredBookingsData)
        }

        if (selectedDate) {
            filteredBookingsData = filterByDate(filteredBookingsData)
        }

        if (selectedFacilityType) {
            filteredBookingsData = filterByFacilityType(filteredBookingsData)
        }

        setFilteredBookings(filteredBookingsData)
    }

    const removeFilters = () => {
        setSearchValue('')
        setSelectedBookingStatus('')
        setSelectedDate('')
        setSelectedFacilityType('')
    }

    return (
        <div className="userBookingsContainer">
            <div className="userBookingsContainer__top">
                <PageRow>
                    <div className="userBookingsContainer__bookingSearch">
                        <div className="userBookingsContainer__bookingSearchTitle">
                            <h2>{t.booking_search[lang]}</h2>
                        </div>
                        <div className="userBookingsContainer__bookingSearchForm">
                            <div className="userBookingsContainer__firstRow">
                                <label htmlFor="textBookingFilter">
                                    {t.booking_search_header[lang]}
                                </label>
                                <input
                                    id="textBookingFilter"
                                    name="textBookingFilter"
                                    onChange={handleSearchChange}
                                    placeholder={
                                        t.text_booking_placeholder[lang]
                                    }
                                    type="text"
                                    value={searchValue}
                                />
                            </div>
                            <div className="userBookingsContainer__secondRow">
                                {filters?.map(
                                    ({
                                        categories,
                                        label,
                                        id,
                                        onChange,
                                        value,
                                    }) => (
                                        <SelectFilter
                                            key={id}
                                            lang={lang}
                                            label={label}
                                            id={id}
                                            categories={categories}
                                            onChange={onChange}
                                            value={value}
                                        />
                                    )
                                )}
                                <div>
                                    <label htmlFor="bookingStartDate">
                                        {t.booking_date[lang]}
                                    </label>
                                    <input
                                        className="userBookingsContainer__dateFilter"
                                        id="bookingStartDate"
                                        onChange={handleDateChange}
                                        type="date"
                                        value={selectedDate}
                                    />
                                </div>
                                <div className="userBookingsContainer__buttonPanel">
                                    <button
                                        className="userBookingsContainer__removeFiltersButton"
                                        onClick={removeFilters}
                                        type="button"
                                    >
                                        {t.remove_filters[lang]}
                                    </button>
                                    <button
                                        className="userBookingsContainer__filtersButton"
                                        onClick={filterBookings}
                                        type="button"
                                    >
                                        {t.search[lang]}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </PageRow>
            </div>
            <div className="userBookingsContainer__bottom">
                <PageRow>
                    <div className="userBookingsContainer__bookingList">
                        {/* <div className="userBookingsContainer__bookingSearchTitle">
                            <h2>{t.booking_list[lang]}</h2>
                        </div> */}
                        <div className="userBookingsContainer__bookingSearchForm">
                            <PaginatedTable
                                columnDefs={columnDefs}
                                lang={lang}
                                rowData={filteredBookings}
                            />
                        </div>
                    </div>
                </PageRow>
            </div>
        </div>
    )
}

export default UserBookingsContainer
