import mapsPageSlugs from './slugs'

export const PAGE = '/:lang/:page'
export const AURRETIK = '/:lang/aurretik/:instanceID'
export const AURRETIK_FACILITY = '/:lang/aurretik/:instanceID/:facilityID'
export const INCIDENCES_BY_ID = '/:lang/incidencias/:urlID'
export const PAGES_BY_SLUG = '/:lang/:page/:slug'

export const INCIDENCES_NEW = {
    es: '/:lang/kalezaindu/nueva-incidencia',
    eu: '/:lang/kalezaindu/intzidentzia-berria',
}

export const PLANS_SEARCH = {
    es: '/:lang/planes-de-legislatura/buscador',
    eu: '/:lang/legealdiko-planak/bilatzaile',
}

export const PLANS_BY_SLUG_PROGRESS = {
    es: '/:lang/planes/:slug/progreso',
    eu: '/:lang/planak/:slug/aurrerapena',
}

export const PLANS_BY_SLUG_SUMMARY = '/:lang/planes/:slug/resumen'

export const PLANS_BY_SLUG = {
    es: '/:lang/planes/:slug',
    eu: '/:lang/planak/:slug',
}

export const ACTIONS_BY_SLUG = {
    es: '/:lang/acciones/:slug',
    eu: '/:lang/ekintzak/:slug',
}

export const PAGE_INDICATORS = {
    es: '/:lang/:page/indicadores/:urlID',
    eu: '/:lang/:page/adierazleak/:urlID',
}

export const PAGE_CATEGORIES = {
    es: '/:lang/:page/categorias/:urlID',
    eu: '/:lang/:page/kategoriak/:urlID',
}

export const IRUDIZ_POSTALS = {
    es: '/:lang/irudiz/:instanceID',
    eu: '/:lang/irudiz/:instanceID',
}

export const MAPS = {
    es: `/:lang/${mapsPageSlugs.es}`,
    eu: `/:lang/${mapsPageSlugs.eu}`,
}

export const HARTU = {
    es: `/:lang/hartu`,
    eu: `/:lang/hartu`,
}

export const HARTU_CANCELATION = {
    es: `/:lang/hartu/cancel`,
    eu: `/:lang/hartu/cancel`,
}

export const HARTU_BOOKING = {
    es: `/:lang/hartu/booking/:facilityID/:date?`,
    eu: `/:lang/hartu/booking/:facilityID/:date?`,
}

export const USER_BOOKINGS = {
    es: `/:lang/reservas`,
    eu: `/:lang/erreserbak`,
}

export const LOGIN = {
    es: `/:lang/login?`,
    eu: `/:lang/login?`,
}

export const HARTU_LOGIN_CHECK = {
    es: `/:lang/check-login`,
    eu: `/:lang/check-login`,
}

export const USER_DATA = {
    es: `/:lang/preferencias-usuario`,
    eu: `/:lang/erabiltzailearen-lehentasunak`,
}

export const HARTU_THANK_YOU = {
    es: `/:lang/hartu/pago-correcto`,
    eu: `/:lang/hartu/ordainketa-zuzena`,
}

export const HARTU_FAIL= {
    es: `/:lang/hartu/pago-error`,
    eu: `/:lang/hartu/ordainketa-akatsa`,
}

export const MI_PAGO_FORM= {
    es: `/:lang/mi-pago-form`,
    eu: `/:lang/mi-pago-form`,
}
