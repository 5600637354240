export const ACTION_CONTAINER = 'actionContainer'
export const AURRETIK_CONTAINER = 'aurretikContainer'
export const CATEGORIES_CONTAINER = 'categoriesContainer'
export const FINDER_CONTAINER = 'finderContainer'
export const HARTU_CANCELATION_CONTAINER = 'hartuCancelationContainer'
export const HARTU_LOGIN_CHECK_CONTAINER = 'hartuLoginCheckContainer'
export const HARTU_CONTAINER = 'hartuContainer'
export const HARTU_BOOKING_CONTAINER = 'hartuBookingContainer'
export const HARTU_THANKYOUPAGE_CONTAINER = 'hartuThankYouPageContainer'
export const HARTU_FAILPAGE_CONTAINER = 'hartuFailPageContainer'
export const INCIDENCE_CONTAINER = 'incidenceContainer'
export const INDICATORS_CONTAINER = 'indicatorsContainer'
export const IRUDIZ_CONTAINER = 'irudizContainer'
export const MAPS_CONTAINER = 'mapsContainer'
export const NEWS_CONTAINER = 'newsContainer'
export const NEW_INCIDENCE_CONTAINER = 'newIncidenceContainer'
export const PAGE_CONTAINER = 'pageContainer'
export const PLAN_CONTAINER = 'planContainer'
export const PLAN_PROGRESS_CONTAINER = 'planProgressContainer'
export const PLAN_SUMMARY_CONTAINER = 'planResumenContainer'
export const USER_BOOKINGS_CONTAINER = 'userBookingsContainer'
export const USER_DATA_CONTAINER = 'userDataContainer'
export const MIPAGO_PAY_NOW = 'MiPagoRedirectContainer'
