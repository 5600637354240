import classNames from 'classnames'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { CalendarIcon } from '../../assets/svg/hartu'
import Button from '../../atoms/Button/Button'
import BODY_OVERFLOW_HIDDEN from '../../constants/styles'
import t from '../../i18n/translations/translations'
import './DatePicker.css'

function DatePicker({
    availableDates,
    closeModal,
    dateProp,
    disabled,
    id,
    isModal,
    lang,
    months,
    onChanged,
    placeholder,
    required,
    setDayFocused,
}) {
    const [date, setDate] = useState(null)
    const [isFocused, setIsFocused] = useState(false)

    const handleFocus = ({ focused }) => {
        if (!isModal) {
            setDayFocused(focused)
        }
        setIsFocused(focused)
    }

    const handleChange = (changedDate) => {
        handleFocus({ focused: false })
        setDate(changedDate, onChanged(changedDate))
    }

    const handleCloseModal = () => {
        closeModal()
        document.body.classList.remove(BODY_OVERFLOW_HIDDEN)
    }

    useEffect(() => {
        if (date === null && dateProp && date !== dateProp) {
            setDate(dateProp)
        }
    }, [date, dateProp])

    const isBlocked = (day) => {
        const today = moment().endOf('day')

        if (day?.isBefore(today, 'day')) {
            return true
        }

        if (availableDates?.length) {
            return !availableDates.some((availableDate) =>
                day.isSame(availableDate, 'day')
            )
        }

        return true
    }

    moment.locale(lang || 'es')

    const isOpened = isModal ? true : isFocused

    return (
        <div
            className={classNames('date-picker-wrapper', {
                'date-picker-wrapper__focused': isFocused,
            })}
        >
            <div
                className={classNames('date-picker', {
                    'date-picker__with_modal': isModal,
                    'date-picker__focused': isFocused,
                    'date-picker__disabled': disabled,
                })}
            >
                {!isFocused && !isModal ? (
                    <span className="date-picker__icon">
                        <CalendarIcon />
                    </span>
                ) : null}
                <SingleDatePicker
                    date={date}
                    focused={isOpened}
                    hideKeyboardShortcutsPanel
                    id={id}
                    isDayBlocked={isBlocked}
                    isOutsideRange={() => false}
                    keepOpenOnDateSelect
                    numberOfMonths={months || 1}
                    onDateChange={handleChange}
                    onFocusChange={disabled ? null : handleFocus}
                    placeholder={placeholder}
                    readOnly={disabled}
                    required={required}
                />
            </div>
            {isOpened && (
                <div className="date-picker__button">
                    <Button aria-haspopup="dialog" onClick={handleCloseModal}>
                        {date
                            ? t.select_this_day[lang]
                            : t.date_picker_close[lang]}
                    </Button>
                </div>
            )}
        </div>
    )
}
export default DatePicker
