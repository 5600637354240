import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PageRow from '../../atoms/PageRow/PageRow'
import t from '../../i18n/translations/translations'
import {
    getInstancias,
    getUserUZInfo,
    updateUserUz,
    verify_email,
} from '../../store/actions'
import { setCookie, getCookie } from '../../utils/cookiesFunctions'
import './UserDataContainer.css'
import { Alert } from '@mui/material'

function UserDataContainer({ data, match }) {
    const searchParams = new URLSearchParams(
        window.location.href.split(`?`)[
            window.location.href.split(`?`).length - 1
        ]
    )
    const userId = searchParams.get('sid')

    if (typeof userId !== 'undefined' && userId !== null) {
        setCookie('SID', userId)
        location.replace(window.location.origin + window.location.pathname)
    }

    const dispatch = useDispatch()

    const { pages } = data
    const { data: pageData } = pages
    const { lang } = match
    const { id_instancia } = pageData[0].idinstancia
    const { titulo } = pageData[0]

    const idInstancia = data.pages.data[0].idinstancia

    useEffect(() => {
        const getUserInfo = (payload) => dispatch(getUserUZInfo(payload))
        const getInstances = (payload) => dispatch(getInstancias(payload))

        getUserInfo({ userId, lang, idInstancia })
        getInstances()
    }, [dispatch])

    const { instances, userData, userFormState, emailBBDD } = useSelector(
        ({ hartu }) => ({
            userData: hartu?.userInfo?.data,
            userFormState: hartu?.userFormState,
            instances: hartu?.instancesList?.data,
            emailBBDD: hartu?.userInfo?.data?.email,
        })
    )

    const [email, setEmail] = useState('')
    const [validationMessage, setValidationMessage] = useState(null)
    const [isVerified, setIsVerified] = useState(false)
    const [census, setCensus] = useState('')
    const [birthDate, setBirthDate] = useState(null)
    const [message, setMessage] = useState('')
    const [showMessage, setShowMessage] = useState(false)
    const [typeMessage, setTypeMessage] = useState('')
    const [instanceCensus, setInstanceCensus] = useState(null)
    const [emailError, setEmailError] = useState('')
    const [sendForm, setSendForm] = useState(true)

    useEffect(() => {
        setEmail(userData?.email)
        setCensus(userData?.id_instance_census)
        setBirthDate(userData?.birthday)
        setIsVerified(userData?.email_verified)
        if (instances) {
            instances.map((option) => {
                if (option.id === userData.id_instance_census) {
                    setInstanceCensus(option.name[lang])
                }
            })
        }

        if (!userData?.id_person) {
            setMessage(
                <>
                    {t.error_id_person_null[lang]}
                    <br />
                    {t.error_id_person_null_2[lang]}
                </>
            )
            setShowMessage(true)
        } else if (!census) {
            setMessage(
                <>
                    {t.warning_no_censado[lang]}
                    <br />
                    {t.warning_no_censado_2[lang]}
                </>
            )
            setShowMessage(true)
        } else if (showMessage) {
            setShowMessage(false)
        }
    }, [census, userData?.id_person, lang])

    useEffect(() => {
        if (userFormState) {
            setShowMessage(true)
            setTypeMessage(userFormState)

            if (userFormState === 'ERROR') {
                setMessage(t.error_datos_no_guardados[lang])
            } else {
                setMessage(t.success_datos_guardados[lang])
            }
        }
    }, [userFormState])

    const updateUser = (payload) => dispatch(updateUserUz(payload))

    const handleSubmit = () => {
        updateUser({
            newCensus: census,
            newEmail: email,
            userId,
            lang: lang,
        })
        setTimeout(() => {
            window.location.reload()
        }, '1000')
    }

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        return emailRegex.test(email)
    }

    const handleEmailChange = (e) => {
        const newEmail = e.target.value
        setEmail(newEmail)
        if (newEmail && !validateEmail(newEmail)) {
            setEmailError(t.error_verified_email[lang])
            setSendForm(true)
        } else {
            setEmailError('')
            setSendForm(false)
        }
    }

    const handleVerifyEmail = () => {
        if (!isVerified) {
            dispatch(verify_email({ newEmail: email, lang, userId }))
            setEmailError(t.email_sended[lang])
        }
    }

    const isButtonDisabled =
        isVerified ||
        email === '' ||
        emailError ||
        !emailBBDD ||
        emailBBDD === ''

    return (
        <div className="userDataContainer">
            <div className="userDataContainer__content">
                <PageRow>
                    {userData ? (
                        <div className="userDataContainer__form">
                            <h2 className="userDataContainer__form-header">
                                {t.user_info[lang]}
                            </h2>
                            {showMessage && (
                                <Alert
                                    className="warningMessage"
                                    severity="warning"
                                >
                                    {message}
                                </Alert>
                            )}
                            <div>
                                <label htmlFor="username">{t.name[lang]}</label>
                                <input
                                    disabled
                                    type="text"
                                    name="username"
                                    id="username"
                                    value={userData.name}
                                />
                            </div>
                            <div>
                                <label htmlFor="surname1">{`${t.surname[lang]} 1`}</label>
                                <input
                                    disabled
                                    type="text"
                                    name="surname1"
                                    id="surname1"
                                    value={userData.lastname}
                                />
                            </div>
                            <div>
                                <label htmlFor="surname2">{`${t.surname[lang]} 2`}</label>
                                <input
                                    disabled
                                    type="text"
                                    name="surname2"
                                    id="surname2"
                                    value={userData.second_lastname}
                                />
                            </div>
                            <div>
                                <label htmlFor="dni">{t.dni[lang]}</label>
                                <input
                                    disabled
                                    type="text"
                                    name="dni"
                                    id="dni"
                                    value={userData.dni}
                                />
                            </div>
                            <div>
                                <label htmlFor="resident">
                                    {t.resident[lang]}
                                </label>
                                <input
                                    disabled
                                    type="text"
                                    name="resident"
                                    id="resident"
                                    value={
                                        userData.censed_entity
                                            ? t.yes[lang]
                                            : t.no[lang]
                                    }
                                />
                            </div>
                            <div>
                                <label htmlFor="census">{t.census[lang]}</label>
                                <input
                                    disabled
                                    type="text"
                                    name="instanceCensus"
                                    id="instanceCensus"
                                    value={userData.census_name}
                                />
                            </div>
                            <div>
                                <label htmlFor="birthday">
                                    {t.birthday[lang]}
                                </label>
                                <input
                                    disabled
                                    type="text"
                                    name="birthDate"
                                    id="birthDate"
                                    value={birthDate}
                                />
                            </div>
                            <div>
                                <label htmlFor="email">{t.email[lang]}</label>
                                <input
                                    name="email"
                                    id="email"
                                    onChange={handleEmailChange}
                                    type="email"
                                    value={email}
                                />
                            </div>
                            <div
                                className={`emailErrorContent ${
                                    emailError
                                        ? 'emailErrorContent--visible'
                                        : 'emailErrorContent--hidden'
                                }`}
                            >
                                <label htmlFor="emailErrorbutton"></label>
                                {emailError && (
                                    <p className="emailError">{emailError}</p>
                                )}
                            </div>
                            <div className="emailContent">
                                <label htmlFor="button"></label>
                                <button
                                    onClick={handleVerifyEmail}
                                    className="validation"
                                    disabled={isButtonDisabled}
                                >
                                    {isVerified
                                        ? t.email_verified[lang]
                                        : t.validation_message[lang]}
                                </button>
                            </div>
                            <div className="buttonContent">
                                <label htmlFor="button"></label>
                                <div className="submitUserData">
                                    <button
                                        disabled={sendForm}
                                        onClick={handleSubmit}
                                        type="submit"
                                    >
                                        {t.save[lang]}
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </PageRow>
            </div>
        </div>
    )
}

export default UserDataContainer
