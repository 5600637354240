import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import HartuCancelationButton from '../../atoms/HartuCancelationButton/HartuCancelButton'
import PageRow from '../../atoms/PageRow/PageRow'
import HartuBookingGrid from '../../components/Hartu/HartuBookingGrid/HartuBookingGrid'
import ThankYouPage from '../../compositions/ThankYouPage/ThankYouPage'
import FailPage from '../../compositions/FailPage/FailPage'
import STATES from '../../constants/states'
import {
    getAvailableDatesByFacilityAndType,
    getAvailableHoursByFacilityAndType,
    saveHartuFacilityBooking as saveHartuFacilityBookingAction,
    setHartuFacilityDetail as setHartuFacilityDetailAction,
} from '../../store/actions'

function HartuBookingContainer({ isMobile, isTablet, match }) {
    const dispatch = useDispatch()

    const {
        availableDates,
        bookingCode,
        bookingFormState,
        date,
        datesLoading,
        errorBookingCode,
        facility,
        facilityHoursAvailable,
        facilityData,
        facilityName,
        formValuesBooking,
        hoursLoading,
        instanceID,
        price
    } = useSelector((state) => ({
        availableDates: state.hartu.dates?.data,
        bookingCode: state.hartu.booking?.data?.data?.booking_code,
        bookingFormState: state.hartu.bookingFormState,
        date: state.hartu.booking?.data?.data?.date,
        datesLoading: state.hartu.datesLoading,
        errorBookingCode: state.hartu.booking?.data?.code,
        facility: state.hartu.facility,
        facilityHoursAvailable: state.hartu.hours?.data?.data,
        facilityData: state.hartu.facility?.data?.data,
        facilityName: state.hartu.booking?.data?.data?.facility_name,
        formValuesBooking: state.hartu.booking?.bookingSave,
        hoursLoading: state.hartu.hoursLoading,
        instanceID: state.pages.hartu?.data[0]?.idinstancia,
        price: state.hartu.booking?.data?.data?.price
    }))

    const getAvailableDates = (payload) =>
        dispatch(getAvailableDatesByFacilityAndType(payload))
    const getAvailableHours = (payload) =>
        dispatch(getAvailableHoursByFacilityAndType(payload))
    const saveHartuFacilityBooking = (payload) =>
        dispatch(saveHartuFacilityBookingAction(payload))
    const setHartuFacilityDetail = (payload) =>
        dispatch(setHartuFacilityDetailAction(payload))

    const userId = null

    useEffect(() => {
        const { lang, facilityID, facilityType } = match

        if (facilityID && instanceID && lang) {
            setHartuFacilityDetail({
                facilityID,
                facilityType,
                idInstancia: instanceID,
                lang,
                userId,
            })
        }
    }, [])

    const { lang, date: paramsDate } = match
    return bookingFormState === STATES.SUCCESS ? (
        <ThankYouPage
            match={match}
        >
        </ThankYouPage>
    ) : bookingFormState === STATES.ERROR ? (
    
        <FailPage  
            match={match}
        >
        </FailPage>
    ) : (
        <>
            {facilityData ? (
                <div className={`${isMobile || isTablet ? '' : 'pageRow'}`}>
                    <HartuBookingGrid
                        availableDates={availableDates}
                        bookingFormState={bookingFormState}
                        data={facilityData}
                        datesLoading={datesLoading}
                        errorBookingCode={errorBookingCode}
                        facility={facility}
                        facilityHoursAvailable={facilityHoursAvailable}
                        formValuesBooking={
                            formValuesBooking || facilityData['user_prize']
                        }
                        getAvailableDates={getAvailableDates}
                        getAvailableHours={getAvailableHours}
                        hoursLoading={hoursLoading}
                        instanceID={instanceID}
                        lang={lang}
                        paramsDate={paramsDate}
                        saveHartuFacilityBooking={saveHartuFacilityBooking}
                    />
                </div>
            ) : null}
            <PageRow>
                <HartuCancelationButton lang={lang} />
            </PageRow>
        </>
    )
}

export default HartuBookingContainer
