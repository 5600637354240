import React from 'react'
import t from '../../i18n/translations/translations'
import InternalLink from '../InternalLink/InternalLink'
import './HartuCancelationButton.css'
import IconCancelBooking from '../../assets/svg/hartu/IconCancelBooking'

export default function HartuCancelationButton({ lang }) {
    return (
        <aside className="hartuCancelationButton__container">
            <InternalLink
                url={`/${lang}/hartu/cancel`}
                className="hartuCancelationButton"
            >
                <IconCancelBooking/>
                {t.make_cancelation_title[lang]}
            </InternalLink>
        </aside>
    )
}
