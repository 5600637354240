import React from 'react'

export default function FailWithCircle() {
    return (
        <svg
            width="53"
            height="52"
            viewBox="0 0 53 52"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <ellipse
                cx="26.5"
                cy="26"
                rx="24.5"
                ry="24"
                stroke="currentColor"
                stroke-width="3"
            />
            <path
                d="M37.936 14.6662L16.4362 38.5674"
                stroke="currentColor"
                stroke-width="3"
                stroke-linecap="round"
            />
            <path
                d="M15.5512 14.9007L37.5518 38.5664"
                stroke="currentColor"
                stroke-width="3"
                stroke-linecap="round"
            />
        </svg>
    )
}
