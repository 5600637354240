import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';


function MiPagoRedirectContainer() {
    const location = useLocation(); 
    const [formData, setFormData] = useState({ 
        id_mipago: '', 
        payment_back: '' 
    });

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const id_mipago = queryParams.get('id_mipago');
        let payment_back = queryParams.get('payment_back');
        payment_back = `<protocolData><urls><url id='urlVuelta'><![CDATA[http://`+ payment_back +`]]></url></urls></protocolData>`;

        setFormData({ id_mipago: id_mipago || '', payment_back: payment_back || '' });

        const form = document.getElementById('p12FormOids');
            if (form) {
                setTimeout(() => {
                    form.submit();
                }, 500);
                
            }
    }, [location.search]);

    const form = document.getElementById('p12FormOids');

    return (
        <div className="MiPagoFormContainer">
            <form name='p12FormOids' id='p12FormOids' method='POST' action='https://mipago.sandbox.euskadi.eus/p12uiPaymentWar/p12uiRPCDispatcherServlet'>
                <input type='hidden' name='module' value='IU' />
                <input type='hidden' name='p12iOidsPago' value={formData.id_mipago} />
                <input type='hidden' name='p12iProtocolData' value={formData.payment_back} />
            </form>
        </div>
    )
}

export default MiPagoRedirectContainer
