import React from 'react'
import './HartuRemoveFiltersButton.css'
import t from '../../../i18n/translations/translations'

function HartuRemoveFiltersButton({ lang, onClick }) {
    return (
        <button className="removeFiltersButton" onClick={onClick} type="button">
            {t.remove_filters[lang]}
        </button>
    )
}

export default HartuRemoveFiltersButton
