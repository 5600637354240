import transformFacility from '../transformFacility'

const hartuFacilityDataTransformer = data => {

    const facilityData = data?.data?.facility

    const transformed = {
        code: data.code,
        error: data.error,
        data: facilityData && transformFacility({ data: facilityData }),
    }

    return transformed
}

export default hartuFacilityDataTransformer