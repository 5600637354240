import {
    AURRETIK_CONTAINER,
    CATEGORIES_CONTAINER,
    HARTU_BOOKING_CONTAINER,
    HARTU_CANCELATION_CONTAINER,
    HARTU_CONTAINER,
    HARTU_LOGIN_CHECK_CONTAINER,
    HARTU_THANKYOUPAGE_CONTAINER,
    HARTU_FAILPAGE_CONTAINER,
    INCIDENCE_CONTAINER,
    MAPS_CONTAINER,
    NEW_INCIDENCE_CONTAINER,
    PAGE_CONTAINER,
    PLAN_PROGRESS_CONTAINER,
    PLAN_SUMMARY_CONTAINER,
    USER_BOOKINGS_CONTAINER,
    USER_DATA_CONTAINER,
} from '../constants/containersId'

const PAGES_WITH_BREADCRUMBS = [
    AURRETIK_CONTAINER,
    CATEGORIES_CONTAINER,
    HARTU_BOOKING_CONTAINER,
    HARTU_CANCELATION_CONTAINER,
    HARTU_LOGIN_CHECK_CONTAINER,
    HARTU_CONTAINER,
    HARTU_THANKYOUPAGE_CONTAINER,
    HARTU_FAILPAGE_CONTAINER,
    INCIDENCE_CONTAINER,
    MAPS_CONTAINER,
    NEW_INCIDENCE_CONTAINER,
    PAGE_CONTAINER,
    PLAN_PROGRESS_CONTAINER,
    PLAN_SUMMARY_CONTAINER,
    USER_BOOKINGS_CONTAINER,
    USER_DATA_CONTAINER
]

const checkShouldShowBreadcrumbsByPageId = id => PAGES_WITH_BREADCRUMBS.includes(id)

export default checkShouldShowBreadcrumbsByPageId
