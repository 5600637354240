import React from 'react'

export default function IconCancelBooking() {
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="12.5"
                cy="12.5"
                r="11"
                stroke="white"
                stroke-width="3"
            />
            <path d="M20 4L6.5 20.5" stroke="white" stroke-width="3" />
        </svg>
    )
}
