import React from 'react'

export default function IconCancel() {
    return (
        <svg
            width="24"
            height="18"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="9.5"
                cy="9.5"
                r="8.845"
                stroke="var(--color-main)"
                stroke-width="1.31"
            />
            <path
                d="M13.6055 5.39453L5.39517 13.6048"
                stroke="var(--color-main)"
                stroke-width="1.31"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M13.6055 13.6055L5.39517 5.39517"
                stroke="var(--color-main)"
                stroke-width="1.31"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
