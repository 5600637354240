import React from 'react'
import { useDispatch } from 'react-redux'
import t from '../../i18n/translations/translations'
import './MiPagoModal.css'
import Cross from '../../assets/svg/Cross'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { MI_PAGO_HARTU_FUNCIONALITY } from '../../constants/hartu'
import { miPagoPayNow, miPagoPayLater } from '../../store/actions'
import InfoPay from '../../assets/svg/hartu/InfoPay'
import { useNavigate } from 'react-router-dom'

function MiPagoModal({
    isOpened,
    handleClose,
    instanceID,
    id_booking = null,
    id_user = null,
    total_price,
    lang,
    id_facility,
    id_facility_type = null,
    bookingDate,
    startBookingHour,
    name,
    lastname,
    email,
    dni,
    cp,
    phone,
    people,
    hasLightCheck,
    hidePayLaterButton,
    facilityName,
}) {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handlePayNow = () => {
        dispatch(
            miPagoPayNow({
                id_instancia: instanceID,
                id_user: id_user,
                total_price: total_price,
                id_booking: id_booking,
                functionality_type: MI_PAGO_HARTU_FUNCIONALITY,
                lang: lang,
                id_facility: id_facility,
                id_facility_type: id_facility_type,
                bookingDate: bookingDate,
                startBookingHour: startBookingHour,
                name: name,
                lastname: lastname,
                email: email,
                dni: dni,
                cp: cp,
                phone: phone,
                people: people,
                hasLightCheck: hasLightCheck,
            })
        )
    }

    // Función para manejar el pago posterior
    const handlePayLater = async () => {
        try {
            const success = dispatch(
                miPagoPayLater({
                    id_instancia: instanceID,
                    id_user: id_user,
                    total_price: total_price,
                    id_booking: id_booking,
                    functionality_type: MI_PAGO_HARTU_FUNCIONALITY,
                    lang: lang,
                    id_facility: id_facility,
                    id_facility_type: id_facility_type,
                    bookingDate: bookingDate,
                    startBookingHour: startBookingHour,
                    name: name,
                    lastname: lastname,
                    email: email,
                    dni: dni,
                    cp: cp,
                    phone: phone,
                    people: people,
                    hasLightCheck: hasLightCheck,
                })
            )
            if (success == true) {
                navigate('/thank-you')
            }
        } catch (error) {
            console.error('Error al procesar el pago:', error)
        }
    }

    return (
        <Dialog
            id="dialog-content"
            open={isOpened}
            onClose={handleClose}
            className="dialogContainer"
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Button onClick={handleClose} className="closeButton">
                <Cross width="20px" />
            </Button>
            <DialogTitle id="alert-dialog-title">
                <>
                    <div className="alert-dialog-title-message">{t.datos_reserva["eu"]}</div>
                    <div className="alert-dialog-title-message-subtitle">{t.datos_reserva["es"]}</div>
                </>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <div className="hartuBookingform__confirmation">
                        <div className="hartuBookingform__confirmation_content">
                            <div>
                                <i className="fa fa-map-marker fa-lg" />
                                <p>{facilityName}</p>
                            </div>
                            <div>
                                <i className="fa fa-calendar fa-lg" />
                                <p>{bookingDate}</p>
                            </div>
                            <div>
                                <i className="fa fa-clock-o fa-lg" />
                                <p>{startBookingHour}</p>
                            </div>
                            <div>
                                <i className="fa fa-credit-card fa-lg" />
                                <p>{`${total_price} €`}</p>
                            </div>
                            <div className="hartuBookingform__confirmation_content_info">
                                {/* <InfoPay /> */}
                                <i class="fa fa-exclamation-triangle" />
                                <p>{t.pay_warning_message[lang]}</p>
                            </div>
                        </div>
                    </div>
                </DialogContentText>
                <div id="form-content">
                    <Button onClick={handlePayNow} className="payBookingButton">
                        {t.pay_now[lang]}
                    </Button>
                    {id_user && !hidePayLaterButton && (
                        <Button
                            onClick={handlePayLater}
                            className="payBookingButton"
                        >
                            {t.pay_later[lang]}
                        </Button>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default MiPagoModal
