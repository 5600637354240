import { withFormik } from 'formik'
import moment from 'moment'
import React, { useState } from 'react'
import { toISODateString } from 'react-dates'
import { object, string } from 'yup'
import { Time } from '../../assets/svg/hartu'
import Button from '../../atoms/Button/Button'
import FormError from '../../atoms/FormError/FormError'
import FormGroup from '../../atoms/FormGroup/FormGroup'
import DatePicker from '../../components/DatePicker/DatePicker'
import STATES from '../../constants/states'
import t from '../../i18n/translations/translations'
import './HartuBookingDateForm.css'

function HartuTimeSelect({
    availableHours,
    date,
    disabled,
    hoursLoading,
    lang,
    onChange,
}) {
    let optionText = ''
    if (hoursLoading) {
        optionText = t.loading[lang]
    } else if (disabled) {
        optionText = t.error_no_available_hours[lang]
    } else {
        optionText = t.select_a_timetable[lang]
    }

    let renderOptions = null
    if (availableHours?.length) {
        renderOptions = availableHours.map((option) => (
            <option key={option} value={option}>
                {option}
            </option>
        ))
    } else if (date) {
        renderOptions = (
            <option disabled>{t.error_no_available_timetables[lang]}</option>
        )
    } else {
        renderOptions = <option disabled>{t.error_select_a_date[lang]}</option>
    }

    return (
        <div className="hartuTimeSelect__wrapper">
            <span>
                <Time />
            </span>
            <select
                className="hartuTimeSelect__select"
                onChange={onChange}
                defaultValue=""
                disabled={disabled}
            >
                <option value="" disabled hidden>
                    {optionText}
                </option>
                {renderOptions}
            </select>
        </div>
    )
}

const HartuBookingDateForm = withFormik({
    displayName: 'HartuBookingDateForm',
    handleSubmit: (values, formikBag) => {
        if (typeof formikBag.props.handleSubmit !== 'function') {
            return
        }
        formikBag.props.handleSubmit(values)
    },
    mapPropsToValues: (props) => ({
        date: props.paramsDate ? props.paramsDate : '',
        time: '',
        lang: props.lang,
    }),

    validationSchema: (props) => {
        const { lang } = props
        return object().shape({
            date: string().required(t.error_required_date[lang]),
            time: string().required(t.error_required_time[lang]),
        })
    },
})(({
    availableDates,
    availableDaysMessage,
    date,
    dateFocused,
    datesLoading,
    errors,
    facilityHoursAvailable,
    getAvailableHours,
    handleCloseConcurrenceError,
    handleSelectedHour,
    handleSubmit,
    handleTime,
    hoursLoading,
    lang,
    paramsDate,
    setDayFocused,
    setFieldValue,
    state,
    touched,
    values,
}) => {
    const [optionSelected, setOptionSelected] = useState(null)

    const handleDateChange = (changedDate) => {
        getAvailableHours(changedDate)
        setFieldValue('date', changedDate ? toISODateString(changedDate) : '')
        setFieldValue('time', '')
    }

    const handleTimeChange = (e) => {
        setFieldValue('time', e.target.value)
        handleTime(e.target.value)

        const filteredData = facilityHoursAvailable.filter(
            (item) => item.range === e.target.value
        )
        setOptionSelected(filteredData[0])
        handleSelectedHour(filteredData[0])
    }

    const availableHours = facilityHoursAvailable?.map((item) => item.range)

    const hasAvailableDates = !datesLoading && availableDates?.length

    let datePlaceholderText

    if (datesLoading) {
        datePlaceholderText = t.loading[lang]
    } else if (hasAvailableDates) {
        datePlaceholderText = t.select_one_day[lang]
    } else {
        datePlaceholderText = t.error_no_available_dates[lang]
    }

    return (
        <form
            className="hartuBookingDateForm hartuBookingGrid__content--form"
            onSubmit={() => {
                handleSubmit()
                handleCloseConcurrenceError()
            }}
        >
            <div className="hartuBookingDateForm__input--wrapper">
                <FormGroup
                    errors={touched.date && errors.date ? errors.date : null}
                    label={null}
                    required
                >
                    <DatePicker
                        availableDates={availableDates}
                        dateProp={paramsDate ? moment(paramsDate) : null}
                        datesLoading={datesLoading}
                        disabled={!hasAvailableDates}
                        id="date"
                        lang={lang}
                        onChanged={(value) => handleDateChange(value)}
                        placeholder={datePlaceholderText}
                        required
                        setDayFocused={setDayFocused}
                    />
                </FormGroup>
            </div>

            <div className="server-group__info">
                <p>{availableDaysMessage}</p>
            </div>

            {!dateFocused && (
                <FormGroup
                    className="hartuBookingDateForm__input--wrapper"
                    errors={touched.time && errors.time ? errors.time : null}
                    label={null}
                    required
                >
                    <HartuTimeSelect
                        disabled={!hasAvailableDates}
                        hoursLoading={hoursLoading}
                        date={date}
                        availableHours={availableHours}
                        lang={lang}
                        onChange={(e) => handleTimeChange(e)}
                    />
                </FormGroup>
            )}

            {!dateFocused && values.time && (
                <div className="server-group__info">
                    <p>
                        {`${t.actual_capacity[lang]}: ${optionSelected.capacity}`}
                    </p>
                </div>
            )}

            {!dateFocused && (
                <div className="hartuBookingDateForm__submit">
                    <Button
                        type="submit"
                        disabled={
                            !hasAvailableDates || !values.date || !values.time
                        }
                    >
                        {t.make_booking[lang]}
                    </Button>
                </div>
            )}
            {state === STATES.ERROR && (
                <FormError className="form-group__errors">
                    {t.error_submit_default[lang]}
                </FormError>
            )}
        </form>
    )
})

export default HartuBookingDateForm
